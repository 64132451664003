// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YaM54KQMlX3xs273bLM5{width:40px;grid-template-rows:0px 40px 0px;border-radius:40px;transform:translateY(-7px)}.oL2Sry0AF71wKrGyuJ0Y{display:none}.oJrJlHqJ2Wdc36Ty8caW{transform:translateY(7px)}.YsEdSS1qZLjs719Z6eoS{border-radius:40px}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeGeometryEditor/dist/graphSystem/display/elbowDisplayManager.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,+BAAA,CACA,kBAAA,CACA,0BAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,yBAAA,CAGJ,sBACI,kBAAA","sourcesContent":[".elbowBlock {\r\n    width: 40px;\r\n    grid-template-rows: 0px 40px 0px;\r\n    border-radius: 40px;\r\n    transform: translateY(-7px);\r\n}\r\n\r\n.hidden {\r\n    display: none;\r\n}\r\n\r\n.translatedConnections {\r\n    transform: translateY(7px);\r\n}\r\n\r\n.roundSelectionBorder {\r\n    border-radius: 40px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var elbowBlock = `YaM54KQMlX3xs273bLM5`;
export var hidden = `oL2Sry0AF71wKrGyuJ0Y`;
export var translatedConnections = `oJrJlHqJ2Wdc36Ty8caW`;
export var roundSelectionBorder = `YsEdSS1qZLjs719Z6eoS`;
export default ___CSS_LOADER_EXPORT___;
