// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FlaOf6IJcF20u6Plp_QV{width:40px;grid-template-rows:0px 40px 0px;border-radius:5px;transform:translateY(-7px)}.yTnVTLhIEUUhDGtLN9Mk{display:none}.hkj3mZ6tMUTu_Vz6jpfP{transform:translateY(7px)}.K45TSzT4BuxalY1albgY{border-radius:0px}.yPyBpPprbySwEa4dg8v3{width:20px;transform:translate(16px, 10px);background:rgba(0,0,0,0);border:none;display:block;content:"?";grid-row:1}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeGeometryEditor/dist/graphSystem/display/debugDisplayManager.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,+BAAA,CACA,iBAAA,CACA,0BAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,yBAAA,CAGJ,sBACI,iBAAA,CAGJ,sBACI,UAAA,CACA,+BAAA,CACA,wBAAA,CACA,WAAA,CACA,aAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".debugBlock {\r\n    width: 40px;\r\n    grid-template-rows: 0px 40px 0px;\r\n    border-radius: 5px;\r\n    transform: translateY(-7px);\r\n}\r\n\r\n.hidden {\r\n    display: none;\r\n}\r\n\r\n.translatedConnections {\r\n    transform: translateY(7px);\r\n}\r\n\r\n.roundSelectionBorder {\r\n    border-radius: 0px;\r\n}\r\n\r\n.debugContent {\r\n    width: 20px;\r\n    transform: translate(16px, 10px);\r\n    background: transparent;\r\n    border: none;\r\n    display: block;\r\n    content: \"?\";\r\n    grid-row: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var debugBlock = `FlaOf6IJcF20u6Plp_QV`;
export var hidden = `yTnVTLhIEUUhDGtLN9Mk`;
export var translatedConnections = `hkj3mZ6tMUTu_Vz6jpfP`;
export var roundSelectionBorder = `K45TSzT4BuxalY1albgY`;
export var debugContent = `yPyBpPprbySwEa4dg8v3`;
export default ___CSS_LOADER_EXPORT___;
